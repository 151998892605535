import React from "react";
import "../style/ContactInfoPage.css"; // Import the CSS file for styling
import Navbar from "../sections/Navbar";

const ContactInfoPage = () => {
  return (
    <div>
      <Navbar />
      <div className="contact-info-page">
        <h1>Contact Information</h1>
        <div
          data-aos="fade-up"
          data-aos-duration="300"
          className="card company-details"
        >
          <h2>Company Details</h2>
          <p>
            Company Name: <span style={{ fontWeight: "bold" }}>DealsKart</span>
          </p>
          <p>
            Phone: <span style={{ fontWeight: "bold" }}>+971 55 137 4295</span>
          </p>
          <p>
            Email: <span style={{ fontWeight: "bold" }}>info@dealskart.me</span>
          </p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="700"
          className="card social-media"
        >
          <h2>Social Media</h2>
          <p>
            Facebook:{" "}
            <a
              target="_blank"
              href="https://www.facebook.com/profile.php?id=100093962725638&mibextid=ZbWKwL"
            >
              DealsKart AE
            </a>
          </p>

          <p>
            Instagram:{" "}
            <a href="https://instagram.com/dealskart.ae?igshid=MzNlNGNkZWQ4Mg==">
              dealskart.ae
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactInfoPage;
