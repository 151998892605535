import React, { useState } from "react";
import appLogo from "../assets/img/app-logo.png";
import appName from "../assets/img/app-name.png";
import "../style/navbar.css";
import { Link } from "react-router-dom";
const Navbar = () => {
  const [showCollapsedMenu, setShowCollapsedMenu] = useState(false);

  const toggleMenu = () => {
    setShowCollapsedMenu(!showCollapsedMenu);
  };
  return (
    <div className="bg-nav-light">
      <div className="container">
        <div className="row">
          {/* <div className="col-md-1"></div> */}
          <div className="col">
            <nav className="navbar navbar-expand-lg navbar-light bg-light justify-content-between">
              <Link to={"/"}>
                {" "}
                <div className="navbar-brand">
                  <img
                    alt="logo"
                    style={{ height: "25px", width: "30px" }}
                    src={appLogo}
                  ></img>

                  <span>
                    <img
                      alt="app-name"
                      style={{ height: "16px", width: "100px" }}
                      src={appName}
                    ></img>
                  </span>
                </div>
              </Link>

              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleMenu}
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className={
                  showCollapsedMenu
                    ? "collapse navbar-collapse show"
                    : "collapse navbar-collapse"
                }
                id="navbarSupportedContent"
              >
                <div>
                  {" "}
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item ">
                      <Link to={"/credit-cards"} className="nav-link">
                        Credit Cards
                      </Link>
                    </li>
                    {/* <li className="nav-item ">
                      <Link to={"/contact-us"} className="nav-link">
                        Contact Us
                      </Link>
                    </li> */}
                    <li className="nav-item ">
                      <Link to={"/privacy-policy"} className="nav-link">
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
