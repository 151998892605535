import React from 'react';
import '../style/UserInputModal.css'; // Assuming a separate CSS file for styling

const ThankYouModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div
       data-aos="fade-up"
       data-aos-anchor-placement="top-bottom"
       data-aos-duration="500"
      className="modal-container">
        <div className="thank-you-content">
          <span className="icon">🎉</span> {/* You can replace this with a custom icon */}
          <h2>Thank You!</h2>
          <p>Your details have been successfully submitted. We'll be in touch with you soon!</p>
          <button onClick={onClose} className="modal-button close">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYouModal;
