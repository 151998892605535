import React from "react";
import styled from "styled-components";


const Footer = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "20px 0" }}
          >
            <div></div>
            <StyleP style={{textAlign : 'center'}} className="whiteColor font13">
              © {getCurrentYear()} -{" "}
              <span style={{color : 'lightBlue'}} className=" font13">DealsKart</span> All Right
              Reserved
              {/* <div style={{paddingTop: '5px'}}><Link to={"/privacy-policy"}> View Privacy Policy </Link></div> */}
            </StyleP>

           <div></div>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 10px 0;
  }
`;

export default Footer;
