import React, { useEffect, useState } from "react";
import "../style/UserInputModal.css"; // Assuming separate CSS file for modal
import "../style/CreditCardContainer.css";

const UserInputModal = ({
  isOpen,
  lead,
  isLoading,
  card,
  onClose,
  onConfirm,
}) => {
  const [phoneNumber, setPhoneNumber] = useState("+971");
  const [email, setEmail] = useState("");

  const [error, setError] = useState("");

  useEffect(() => {
    if (lead != null) {
      setEmail(lead.email);
      setPhoneNumber(lead.phone);
    }
  }, []);

  const handleConfirm = () => {
    setError("");
    if (phoneNumber.length > 12 && phoneNumber.length < 18) {
      let lead = {
        phone: phoneNumber,
        email: email,
      };
      onConfirm(lead);
      onClose(); // Close the modal after confirmation
    } else {
      setError("please enter a valid phone number!");
    }
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Ensure the prefix is always there
    if (!value.startsWith("+971")) {
      setPhoneNumber("+971");
    } else {
      const value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
      const formattedNumber = "+971" + value.slice(3); // Ensure the "+971" prefix

      if (formattedNumber.length <= 15) {
        // "+971" prefix + max 11 digits
        setPhoneNumber(formattedNumber);
      }
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
    return emailRegex.test(email);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="500"
        className="modal-container"
      >
        <h4 style={{ fontSize: "20px" }}>
          We're excited that you're interested!<span className="icon">😍</span>
        </h4>
        {/* <p style={{ marginTop: "15px", marginBottom: "3px" }}>
          We're excited that you're interested!<span className="icon">😍</span>{" "}
        </p> */}

        <div className="container">
        <h6>Selected Card:</h6>
        <div style={{ height: "5px" }}></div>

          <div className="row">
            <div className="col-md-3"></div>

            <div className="col-md-3">
              <img
                style={{
                  textAlign: "left",
                  height: "100px",
                  objectFit: "contain",
                }}
                src={card.imageUrl}
                alt={`${card.name} card`}
                className="card-image"
              />
            </div>
            <div style={{ lineHeight: "14px" }} className="col-md-3">
              <div style={{ textAlign: "center" }} className="card-info">
                <h5>{card.name}</h5>
                <p style={{ textAlign: "center" }} className="min-salary">
                  Minimum Salary:{" "}
                  <span className="salary-amount">
                    {card.minimum_salary ?? "NA"}
                  </span>
                </p>
              </div>
            </div>

            <div className="col-md-3"></div>
          </div>
        </div>

        <p style={{ marginTop: "3px", fontSize: "15px" }}>
          {" "}
          Our Executive will reach out to you for online processing and
          approval. Please provide your phone number proceed.
        </p>
        <input
          type="text"
          placeholder="Your Phone Number"
          className="modal-input"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />
        <p className="error-msg" style={{ color: "red", fontSize: "14px" }}>
          {error}
        </p>
        {/* <input
          type="email"
          placeholder="Your Email Address"
          className="modal-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        /> */}
        <div className="special-offer">
          <p style={{ fontWeight: "bold", color: "black" }}>
            🌟 <strong>Special New Year Offer:</strong> Get{" "}
            <strong>150 AED cashback</strong> upon successful card approval from
            DealsKart. This is in addition to regular card cashback offers.{" "}
            <span className="icon">🥳</span> Offer valid until 5th Jan only!{" "}
            <span className="icon">⏰</span>
          </p>
        </div>
        <div className="button-row">
          {!isLoading && (
            <button onClick={onClose} className="modal-button cancel">
              Cancel
            </button>
          )}
          <button
            onClick={!isLoading ? handleConfirm : null}
            className="modal-button submit"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInputModal;
