import { useEffect } from "react";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import HomePage from "./pages/HomePage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivacyAndPolicyPage from "./pages/PrivacyAndPolicyPage";
import OfferPage from "./pages/OfferPage";
import ContactInfoPage from "./pages/ContactInfoPage";
import CreditCardsPage from "./pages/CreditCardsPage";


function App() {
  useEffect(() => {
    AOS.init();
  });

  return (
    <div>
      <Router>
        <Routes>
          <Route path={"/"} element={<HomePage />} />
          <Route path={"/credit-cards"} element={<CreditCardsPage />} />
          <Route path={"/credit-cards/:id"} element={<CreditCardsPage />} />
          <Route path={"/privacy-policy"} element={<PrivacyAndPolicyPage />} />
          <Route path={"/contact-us"} element={<ContactInfoPage />} />
          <Route path={"/:path"} element={<OfferPage />} />

          {/* <Route exact path={"/*"} element={<HomePage />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
