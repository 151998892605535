import React, { useEffect, useState } from "react";
import SearchBar from "../common/SearchBar";
import "../style/CreditCardsPage.css";
import CreditCardContainer from "../containers/CreditCardContainer";
import Navbar from "../sections/Navbar";
import { useParams } from "react-router-dom";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../init-firebase";
import { v4 as uuidv4 } from "uuid";

const CreditCardsPage = () => {

  const cardData = [
    // Add your card data here
    // Example:
    {
      id: "1232823",
      name: "Emirates Skywards World Elite Credit Card",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fworld-elite.png?alt=media&token=acf79eb1-569b-49d0-a147-40d71fa9f80a",
      bankLogoUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Flogo-light.png?alt=media&token=ff9f924c-9281-46c4-8d90-96cacc71be4a",
      description: `
      Discover a world of extraordinary rewards\n
      Earn up to 40,000 Skywards Miles per month\n
      Joining bonus of 20,000 Skywards Miles\n
      Global airport lounge access and airport transfers`,
      bankName: "RAK Bank",
      features: ["4", "12", "15"],
      minimum_salary: "25,000 AED",
    },
    {
      id: "2142142",
      name: "World Credit Card",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fworld-card.png?alt=media&token=3e4b837c-af50-4b9e-b0e5-3fbbba5e511b",
      bankLogoUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Flogo-light.png?alt=media&token=ff9f924c-9281-46c4-8d90-96cacc71be4a",
      description: `Expand your world\n
Earn up to 10% cashback on travel & supermarket spends\n
Get up to AED 4,000 cashback per month\n
50% off on VOX Cinema\n
      `,
      bankName: "RAK Bank",
      features: ["13", "1"],
      minimum_salary: "20,000 AED",
    },
    {
      id: "3235356",
      name: "Titanium Credit Card",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fcopy-of-credit-card---titanium.png?alt=media&token=a7bf2385-6584-4b0a-ba06-f3c05b8b6e4b",
      bankLogoUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Flogo-light.png?alt=media&token=ff9f924c-9281-46c4-8d90-96cacc71be4a",
      description: `Your ultimate cashback card\n
      Cashback of up to 5% on supermarkets & dining spends\n
Get up to AED 1,500 cashback\n
Enjoy the convenience of RAKMoneyTransfer service`,
      bankName: "RAK Bank",
      features: ["13", "14", "16"],
      minimum_salary: "8,000 AED",
    },
    {
      id: "123254",
      name: "HighFlyer Platinum Credit Card",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fcopy-of-high-flyer.png?alt=media&token=3384bb01-7df0-4894-b5ac-3bdd442c793b",
      bankLogoUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Flogo-light.png?alt=media&token=ff9f924c-9281-46c4-8d90-96cacc71be4a",
      description: `Enjoy the most rewarding travel experiences\n
      Earn up to 350,000 standard RAKrewards monthly\n
Get up to a 30% quarterly RAKrewards bonus\n
No annual fee`,
      bankName: "RAK Bank",
      features: ["0", "12", "15"],
      minimum_salary: "10,000 AED",
    },
    {
      id: "5243421",
      name: "RED Credit Card",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fcopy-of-red-card.png?alt=media&token=7e7d91ba-6b2b-441d-a1c0-3363be0455fc",
      bankLogoUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Flogo-light.png?alt=media&token=ff9f924c-9281-46c4-8d90-96cacc71be4a",
      description: `Enjoy a free-for-life card, great rewards and up to 1.5% cashback\n
        Cashback of up to 1.5% on local & international spends
Get up to AED 300 cashback
Get 1 free monthly international transfer`,
      bankName: "RAK Bank",
      features: ["0", "13", "16"],
      minimum_salary: "5,000 AED",
    },

    {
      id: "15323525",
      name: "Visa Infinite",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fcbd-visa-infinite_img.jpg?alt=media&token=0991ea95-3c99-45f8-be1f-1814f2c40d85",
      bankLogoUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fcbd-logo.gif?alt=media&token=f6306f35-9a8f-48b9-9ace-76473614e151",
      description: `No annual fees for the first year on primary card \n
      Complimentary rounds of Golf at Trump International Golf Club \n
      50% off on movie tickets (Novo, Reel & VOX) \n
      Complimentary Valet Parking every month \n
      Complimentary access to airport lounges worldwide`,
      bankName: "CBD Bank",
      features: ["0", "1", "3", "4"],
      minimum_salary: "15,000 AED",
    },

    {
      id: "1231523",
      name: "Visa Platinum",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fplatinum.png?alt=media&token=d1c06920-db7a-4225-90a0-2ed182f70d36",
      bankLogoUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fcbd-logo.gif?alt=media&token=f6306f35-9a8f-48b9-9ace-76473614e151",
      description: `Free for life. \n
        50% off on movie tickets at Novo cinema\n
        Discount at Emaar entertainment such as KidZania®, Dig It Dubai, VR Theme Park, EKart\n
        Variety of 2-for-1 offers on the ENTERTAINER app.\n
        Several travel, hospitality, dining, wellness, and lifestyle benefits`,
      bankName: "CBD Bank",
      features: ["0", "1"],
      minimum_salary: "5,000 AED",
    },
    {
      id: "4363742",
      name: "CBD One",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fcbd-one-card.png?alt=media&token=5a101b3a-f604-4756-a3b4-c9446b18502d",
      bankLogoUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fcbd-logo.gif?alt=media&token=f6306f35-9a8f-48b9-9ace-76473614e151",
      description: `Up to AED 1000 cashback a month\n
        Choose your card design and your plan\n
        50% off cinemas (Novo, Reel & VOX cinemas)\n
        Free valet parking (at 30 locations across the UAE)\n
        Global Complimentary Airport Lounge Access (over 1,000 airport lounges)\n
        Multi-trip travel Insurance (coverage of up to USD 500,000) by Visa`,
      bankName: "CBD Bank",
      features: ["13", "1", "3", "4"],
      minimum_salary: "5,000 AED",
    },
    {
      id: "75464324",
      name: "CBD Yes Rewards Credit Card",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fyes-card-sliding-door.jpg?alt=media&token=c206b912-7911-4996-804d-54d92a511382",
      bankLogoUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fcbd-logo.gif?alt=media&token=f6306f35-9a8f-48b9-9ace-76473614e151",
      description: `Save up to 15% on fuel\n
        Free valet parking service\n
        Complimentary global airport lounge access\n
        Complimentary rounds of Golf \n
        50% off on movie tickets across (Novo, Reel & VOX)\n
        Purchase Protection\n
        Multi-trip travel Insurance`,
      bankName: "CBD Bank",
      features: ["7", "3", "4", "1"],
      minimum_salary: "5,000 AED",
    },

    {
      id: "4344654",
      name: "Super Saver Signature",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fsupersaver.png?alt=media&token=e6a6d982-7128-4d17-8d40-4e42a001b514",
      bankLogoUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fcbd-logo.gif?alt=media&token=f6306f35-9a8f-48b9-9ace-76473614e151",
      description: `No annual fees for the first year on primary card\n
        Earn up to10% CASHBACK on everyday retail spend categories\n
        50% off on movie tickets (Novo, Reel & VOX) \n
        Complimentary Valet Parking every month\n
        Complimentary access to airport lounges worldwide`,
      bankName: "CBD Bank",
      features: ["13", "4", "3", "1"],
      minimum_salary: "5,000 AED",
    },
    {
      id: "78567543",
      name: "CBD Smiles Visa Platinum Card",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fplatinumf97e31ff458f66deb8c5ff0b00b6b24a.png?alt=media&token=23cccb7b-a8eb-4cd5-be7b-6218c19e0b33",
      bankLogoUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fcbd-logo.gif?alt=media&token=f6306f35-9a8f-48b9-9ace-76473614e151",
      description: `No annual fees for the first year on primary card\n
        5 Smiles points on any spends at Etisalat or on the Smiles UAE mobile app\n
        Up to 2 Smiles points on international spends\n
        Up to 1.5 Smiles points on domestic spends\n
        Buy 1 Get 1 Free dining and lifestyle offers\n
        50% off on movie tickets at Novo cinemas`,
      bankName: "CBD Bank",
      features: ["14", "1"],
      minimum_salary: "5,000 AED",
    },
    {
      id: "5632235",
      name: "CBD Smiles Visa Signature Card",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fsignature299031ff458f66deb8c5ff0b00b6b24a.jpg?alt=media&token=e6bc8eb8-c2bd-439a-baee-92061c41ecb8",
      bankLogoUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fcbd-logo.gif?alt=media&token=f6306f35-9a8f-48b9-9ace-76473614e151",
      description: `No annual fees for the first year on primary card\n
        10 Smiles points on any spends at Etisalat, Smiles UAE mobile app and elGrocer\n
        Up to 3 Smiles points on international spends\n
        Up to 2 Smiles points on domestic spends\n
        50% off on movie tickets across (Novo, Reel & VOX)\n
        Valet Parking at over 30 locations`,
      bankName: "CBD Bank",
      features: ["1", "3"],
      minimum_salary: "12,000 AED",
    },
    {
      id: "8934582",
      name: "World Mastercard",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fworld.png?alt=media&token=870c9fe8-a3cf-445c-8196-431a466aba5f",
      bankLogoUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fcbd-logo.gif?alt=media&token=f6306f35-9a8f-48b9-9ace-76473614e151",
      description: `Annual fee on primary card from first year onwards\n
        50% off on movie tickets across (Novo, Reel & VOX)\n
        Unlimited Golf at Trump International Golf Club\n
        Complimentary fitness membership with Classpass\n
        Global Airport Lounge access\n
        Variety of travel benefits\n
        Valet Parking service\n
        `,
      bankName: "CBD Bank",
      features: ["15", "3", "4", "1"],
      minimum_salary: "30,000 AED",
    },
    {
      id: "5734923",

      name: "Titanium Mastercard",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Ftitanium.png?alt=media&token=f8d0828f-64c7-4240-ab72-54868a84602f",
      bankLogoUrl:
        "https://firebasestorage.googleapis.com/v0/b/gulf-discounts-6622.appspot.com/o/credit-card%2Fcbd-logo.gif?alt=media&token=f6306f35-9a8f-48b9-9ace-76473614e151",
      description: `Annual fee on primary card from first year onwards\n
        Buy 1 Get 1 Free dining and lifestyle offers\n
        Automatic enrollment in CBD Rewards Program\n
        Complimentary access to over 10 Marhaba and CAC Airport lounges in UAE, KSA, Jordan, Kuwait and Egypt.\n
        50% off on movie tickets at Novo cinemas`,
      bankName: "CBD Bank",
      features: ["1", "14", "4", "12"],
      minimum_salary: "15,000 AED",
    },
    // Add more cards as needed
  ];

  const [filteredData, setFilteredData] = useState([]);

  const [selectedId, setSelectedId] = useState('');

  const websiteTrafficDocRef = doc(firestore, "traffic", "website_traffic");
  const [trafficData, setTrafficData] = useState();

  const [loading, setLoading] = useState(false);


  const features = [
    {
      id: "0",
      name: "Free For Life",
    },
    {
      id: "1",
      name: "50% Movie Tickets",
    },
    {
      id: "2",
      name: "Buy 1 Get 1 Free Movie Tickets",
    },
    {
      id: "3",
      name: "Valet Parking",
    },

    {
      id: "4",
      name: "Airport Lounge Access",
    },

    {
      id: "5",
      name: "Buy 1 Get 1 Free dining",
    },

    {
      id: "6",
      name: "Buy 1 Get 1 Free dining",
    },
    {
      id: "7",
      name: "Discount On Fuel",
    },
    {
      id: "8",
      name: "Discount On Groceries",
    },
    {
      id: "9",
      name: "Discount On All Spends",
    },

    {
      id: "12",
      name: "Reward Points",
    },
    {
      id: "13",
      name: "Cashback Card",
    },
    {
      id: "14",
      name: "Dining",
    },
    {
      id: "15",
      name: "Travel Benefits",
    },
    {
      id: "16",
      name: "Money Transfer",
    },
  ];

  const handleSearch = (searchTerm) => {
    console.log(searchTerm);

    if (searchTerm != null) {
      searchTerm = searchTerm.trim().toLowerCase();

      if (searchTerm !== "") {
        const tempD = cardData.filter(
          (card) =>
            card.bankName.toLowerCase().includes(searchTerm) ||
            card.name.toLowerCase().includes(searchTerm)
        );

        console.log("t");
        console.log(tempD);
        setFilteredData(tempD);
      } else {
        // Reset filtered data to the original data if search term is empty
        setFilteredData(cardData);
      }
    }
  };

  const params = useParams()

  useEffect(() => {
    recordTraffic();

    if(params.id != null && params.id.trim()) {
      setSelectedId(params.id);
    }
    handleSearch("");

  }, []);

  const recordTraffic = async () => {
    try {
      const documentSnapshot = await getDoc(websiteTrafficDocRef);

      if (documentSnapshot.exists()) {
        let website_traffic_data = documentSnapshot.data();
        let id = localStorage.getItem("dealskart_uid");

        website_traffic_data["last_updated_at"] = new Date();
        website_traffic_data["total_traffic"] =
          website_traffic_data["total_traffic"] + 1;

        // existing visitor
        if (id != null) {
          //

          const existingTrafficDocRef = doc(
            firestore,
            "traffic/website_traffic/traffics",
            id
          );
          const existingTrafficDocSnapshot = await getDoc(
            existingTrafficDocRef
          );
          if (existingTrafficDocSnapshot.exists()) {
            let tData = existingTrafficDocSnapshot.data();
            //
            const trafficRef = doc(
              firestore,
              "traffic/website_traffic/traffics",
              id
            );


            tData.visitCount = tData.visitCount + 1;
            let tlist = tData.visitDate;
            tlist.push(new Date());
            tData.visitDate = tlist;


            //
            await updateDoc(trafficRef, tData);

            setTrafficData(tData);
          }
        }

        // new visitor
        else {
          website_traffic_data["unique_traffic"] =
            website_traffic_data["unique_traffic"] + 1;

          // create new id
          id = uuidv4();

          // store the user id in local storage
          localStorage.setItem("dealskart_uid", id);

          //
          const trafficRef = doc(
            firestore,
            "traffic/website_traffic/traffics",
            id
          );

          let dData = await getDeviceData();

          let tData = {
            id: id,
            deviceType: getDeviceType(),
            deviceData: dData,
            visitCount: 1,
            appstoreBtnClick: 0,
            playstoreBtnClick: 0,

            visitDate: [new Date()],
          };

          //
          await setDoc(trafficRef, tData);

          setTrafficData(tData);
        }

        await updateDoc(websiteTrafficDocRef, website_traffic_data);

        // setShop(documentSnapshot.data());
      } else {
        // console.log("Document does not exist!");
      }
    } catch (error) {
      // console.log("Error fetching document:", error);
    }
  };

  const getDeviceType = () => {
    var isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    var isDesktop = !isMobile;
    var deviceType = "Unknown";

    if (isMobile) {
      deviceType = "Mobile";
    } else if (isDesktop) {
      deviceType = "Desktop";
    }

    return deviceType;
  };

  const getDeviceData = async () => {
    // https://api.ipify.org?format=json
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    return data;
  };



  const checkName = (selectedName, name) => {
    selectedName = selectedName.replace(/-/g, ' ');
    if(selectedName.toLowerCase() == name.toLowerCase()){
      return true;
    }
    else {
      return false
    }
  }
  

  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="cards-page-container">
              <div style={{ height: "25px" }}></div>
              {/* header */}
              <h2
               data-aos="fade-up"
               data-aos-duration="400"
              className="header-title">Compare, Choose, Apply</h2>

              {/* search bar */}
              <div
               data-aos="fade-up"
               data-aos-duration="400"
               data-aos-delay="50"
              >
                <SearchBar onSearch={handleSearch} />
              </div>
              
             
              {/* filters */}
              {/* <CreditCardFiltersContainer filters={filters}/> */}

              {/* cards list */}
              <div
              className="grid-container">
                {filteredData.map((card, index) => (
                  <CreditCardContainer
                    isCardSelected={(selectedId === card.id || checkName(selectedId, card.name) )? true : false}
                    key={index}
                    card={card}
                    features={features}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditCardsPage;
