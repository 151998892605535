import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Components
import { androidUrl, iosUrl } from "../global/AppConstants";

// sections
import Footer from "../sections/Footer";

// Assets
import QuotesIcon from "../assets/svg/Quotes";
import Dots from "../assets/svg/Dots";
import appStoreImg from "../assets/img/app-store.png";
import playStoreImg from "../assets/img/play-store.png";
import appName from "../assets/img/app-name.png";
import fullImg from "../assets/img/full-img.png";

// css
import "../style/flexboxgrid.min.css";
import "../style/index.css";
import "../style/HomePage.css";
import { NavLink } from "react-router-dom";
import Navbar from "../sections/Navbar";
import { v4 as uuidv4 } from "uuid";
import { firestore } from "../init-firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";

const HomePage = () => {
  const websiteTrafficDocRef = doc(firestore, "traffic", "website_traffic");

  const [trafficData, setTrafficData] = useState();

  useEffect(() => {
    recordTraffic();
  }, []);

  const recordTraffic = async () => {
    try {
      const documentSnapshot = await getDoc(websiteTrafficDocRef);

      if (documentSnapshot.exists()) {
        let website_traffic_data = documentSnapshot.data();
        let id = localStorage.getItem("dealskart_uid");

        website_traffic_data["last_updated_at"] = new Date();
        website_traffic_data["total_traffic"] =
          website_traffic_data["total_traffic"] + 1;

        // existing visitor
        if (id != null) {
          //

          const existingTrafficDocRef = doc(
            firestore,
            "traffic/website_traffic/traffics",
            id
          );
          const existingTrafficDocSnapshot = await getDoc(
            existingTrafficDocRef
          );
          if (existingTrafficDocSnapshot.exists()) {
            let tData = existingTrafficDocSnapshot.data();
            //
            const trafficRef = doc(
              firestore,
              "traffic/website_traffic/traffics",
              id
            );


            tData.visitCount = tData.visitCount + 1;
            let tlist = tData.visitDate;
            tlist.push(new Date());
            tData.visitDate = tlist;


            //
            await updateDoc(trafficRef, tData);

            setTrafficData(tData);
          }
        }

        // new visitor
        else {
          website_traffic_data["unique_traffic"] =
            website_traffic_data["unique_traffic"] + 1;

          // create new id
          id = uuidv4();

          // store the user id in local storage
          localStorage.setItem("dealskart_uid", id);

          //
          const trafficRef = doc(
            firestore,
            "traffic/website_traffic/traffics",
            id
          );

          let dData = await getDeviceData();

          let tData = {
            id: id,
            deviceType: getDeviceType(),
            deviceData: dData,
            visitCount: 1,
            appstoreBtnClick: 0,
            playstoreBtnClick: 0,

            visitDate: [new Date()],
          };

          //
          await setDoc(trafficRef, tData);

          setTrafficData(tData);
        }

        await updateDoc(websiteTrafficDocRef, website_traffic_data);

        // setShop(documentSnapshot.data());
      } else {
        // console.log("Document does not exist!");
      }
    } catch (error) {
      // console.log("Error fetching document:", error);
    }
  };

  const updateBtnClickForUser = async (platform) => {
    let id = localStorage.getItem("dealskart_uid");

    if (trafficData != null && id != null) {
      try {
        let tData = Object.assign({}, trafficData);

        const trafficRef = doc(
          firestore,
          "traffic/website_traffic/traffics",
          id
        );


        if (platform == "ios") {
          tData["appstoreBtnClick"] = tData["appstoreBtnClick"] + 1;
        } else {
          tData["playstoreBtnClick"] = tData["playstoreBtnClick"] + 1;
        }
        
        setTrafficData(tData)
        //
        await updateDoc(trafficRef, tData);
      } catch (error) {
        // console.log("Error in updateBtnClickForUser:", error);
      }
    }
  };

  const updateBtnClickGlobally = async (platform) => {
    updateBtnClickForUser(platform)
    try {
      const documentSnapshot = await getDoc(websiteTrafficDocRef);

      if (documentSnapshot.exists()) {
        let website_traffic_data = documentSnapshot.data();

        website_traffic_data["last_updated_at"] = new Date();
        if (platform == "ios") {
          website_traffic_data["appstore_btn_click"] =
            website_traffic_data["appstore_btn_click"] + 1;
        } else {
          website_traffic_data["playstore_btn_click"] =
            website_traffic_data["playstore_btn_click"] + 1;
        }

        await updateDoc(websiteTrafficDocRef, website_traffic_data);
      }
    } catch (error) {
      // console.log("Error in updateBtnClickGlobally:", error);
    }
  };

  const getDeviceType = () => {
    var isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    var isDesktop = !isMobile;
    var deviceType = "Unknown";

    if (isMobile) {
      deviceType = "Mobile";
    } else if (isDesktop) {
      deviceType = "Desktop";
    }

    return deviceType;
  };

  const getDeviceData = async () => {
    // https://api.ipify.org?format=json
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    return data;
  };

  return (
    <div>
      <Navbar />
      <Wrapper id="home" className="container flexSpaceCenter">
        <LeftSide className="flexCenter">
          <div>
            {/* <div><img style={{width : '50px' , height : '50px'}} src={appLogo}></img></div> */}
            <h1
              data-aos="fade-up"
              data-aos-duration="400"
              className="app-name-img"
            >
              <img alt="" src={appName}></img>
            </h1>
            <HeaderP
              data-aos="fade-up"
              data-aos-duration="500"
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
              className="font13 semiBold"
            >
              Looking for the best deals, offers, and promotions in one place?
              Look no further! Our smart search tool finds the best deals and
              offers from top brands and stores so you can save money and time.
            </HeaderP>

            {/* <BtnWrapper> */}
            {/* <FullButton title="Get Started" />   */}
            <div className="app-link-div fullscreen">
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="600"
                data-aos-delay="250"
                className="app-link"
              >
                <NavLink target="_blank" to={iosUrl} onClick={() => updateBtnClickGlobally('ios')}>
                  <img alt="" src={appStoreImg}></img>
                </NavLink>
              </div>
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="800"
                data-aos-delay="500"
                className="app-link"
              >
                <NavLink target="_blank" to={androidUrl} onClick={() => updateBtnClickGlobally('android')}>
                  {" "}
                  <img alt="" src={playStoreImg}></img>
                </NavLink>
              </div>
            </div>

            {/* </BtnWrapper> */}
          </div>
        </LeftSide>
        <RightSide>
          <ImageWrapper>
            <Img
              data-aos="fade-in"
              data-aos-duration="800"
              className="radius8"
              height={"700px"}
              width={"500px"}
              src={fullImg}
              alt="office"
              style={{ zIndex: 9 }}
            />
            <QuoteWrapper className="flexCenter darkBg radius8">
              <QuotesWrapper>
                <QuotesIcon />
              </QuotesWrapper>
              <div>
                <p
                  data-aos="zoom-in"
                  data-aos-duration="800"
                  data-aos-anchor-placement="top-bottom"
                  className="font15 whiteColor"
                >
                  <em>
                    Compare offers and promotions between different stores with
                    our unique smart search option.
                  </em>
                </p>
              </div>
            </QuoteWrapper>
            <DotsWrapper>
              <Dots />
            </DotsWrapper>
          </ImageWrapper>

          <GreyDiv className="lightBg"></GreyDiv>
          <div className="app-link-div smallscreen">
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="600"
              data-aos-delay="250"
              className="app-link"
            >
              <NavLink  target="_blank" to={iosUrl} onClick={() => updateBtnClickGlobally('ios')}>
                <img alt="" src={appStoreImg}></img>
              </NavLink >
            </div>
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="800"
              data-aos-delay="500"
              className="app-link"
            >
              <NavLink  target="_blank" to={androidUrl} onClick={() => updateBtnClickGlobally('android')}>
                {" "}
                <img alt="" src={playStoreImg}></img>
              </NavLink >
            </div>
          </div>
        </RightSide>
      </Wrapper>
      <Footer />
    </div>
  );
};

const Wrapper = styled.section`
  padding-top: 20px;
  width: 100%;
  min-height: 88vh;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 25px 0 25px 0;
  }
`;

const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin-top : 0px
    padding-top : 0px
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 20px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 0 0;
    text-align: center;
    max-width: 100%;
  }
`;
// const BtnWrapper = styled.div`
//   max-width: 190px;
//   @media (max-width: 960px) {
//     margin: 0 auto;
//   }
// `;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 20px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;

export default HomePage;
