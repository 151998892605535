// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // prod
  apiKey: "AIzaSyB1aTU-zo2X_4nR06TZ96fDDVM73NhTep8",
  authDomain: "gulf-discounts-6622.firebaseapp.com",
  projectId: "gulf-discounts-6622",
  storageBucket: "gulf-discounts-6622.appspot.com",
  messagingSenderId: "905870399325",
  appId: "1:905870399325:web:faa578b1ef4b01c04d3ab4",
  measurementId: "G-WBD01B867S",

  // dev
  // apiKey: "AIzaSyCTdoNbla_knzsD6t9itERZmXm-sD9mBEI",
  // authDomain: "gulf-discounts-3fb9c.firebaseapp.com",
  // projectId: "gulf-discounts-3fb9c",
  // storageBucket: "gulf-discounts-3fb9c.appspot.com",
  // messagingSenderId: "1030480603698",
  // appId: "1:1030480603698:web:0bc497d7910b0023c06a2b",
  // measurementId: "G-0BZNLYSJYQ",
};

export let userSignedIn = false;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Intialize Firestore
export const firestore = getFirestore(app);

