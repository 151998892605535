import React, { useState } from 'react';
import '../style/SearchBar.css'; // Import the CSS file for styling

const SearchBar = ({ onSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        if (onSearch) {
            onSearch(event.target.value);
        }
    };

    return (
        <div className="search-bar">
            <input 
                type="text" 
                className="search-input" 
                placeholder="Search for a card or bank..." 
                value={searchTerm} 
                onChange={handleInputChange} 
            />
            
        </div>
    );
};

export default SearchBar;
