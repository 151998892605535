import React, { useEffect, useState } from "react";
import "../style/CreditCardContainer.css";
import UserInputModal from "../modals/UserInputModal";
import ThankYouModal from "../modals/ThankYouModal";
import { firestore } from "../init-firebase";
import { v4 as uuidv4 } from "uuid";
import { doc, setDoc } from "firebase/firestore";

const CreditCardContainer = ({ card, features, isCardSelected }) => {
  const [userInputModalOpen, setUserInputModalOpen] = useState(false);
  const [isThankYouModalOpen, setThankYouModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [lead, setLead] = useState({
    phone : '+971',
    email : ''

  })

  useEffect(() => {
    if (isCardSelected)
    
    {
      setUserInputModalOpen(true);
      console.log('ss');
      console.log(card);
    }
  }, []);

  const getFeature = (id) => {
    const feature = features.find((feature) => feature.id == id);
    return feature ? feature.name : null;
  };

  const handleModalConfirm = async (leadT) => {
    // console.log("Phone Number: ", phoneNumber);
    // console.log("Email: ", email);
    console.log(leadT);
    setLead(leadT)

    setLoading(true);

    let id = uuidv4();

    let deviceData = await getDeviceData();


    let leadNew = {
      id: id,
      email : leadT.email,
      phone : leadT.phone,
      date: new Date(),
      selectedCard: {
        id: card.id,
        name: card.name,
        bank: card.bankName,
      },
      deviceData: deviceData,
      deviceType: getDeviceType(),
    };

    const cardLeadDoc = doc(firestore, "CreditCard_leads", id);
    await setDoc(cardLeadDoc, leadNew);

    setLoading(false);
    setUserInputModalOpen(false);
    setThankYouModalOpen(true); // Show thank you modal after submission

    // You can now use this data as needed, e.g., send to an API, store in state, etc.
  };

  const getDeviceType = () => {
    var isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    var isDesktop = !isMobile;
    var deviceType = "Unknown";

    if (isMobile) {
      deviceType = "Mobile";
    } else if (isDesktop) {
      deviceType = "Desktop";
    }

    return deviceType;
  };

  const getDeviceData = async () => {
    // https://api.ipify.org?format=json
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    return data;
  };

  const handleImIntrested = () => {
    setUserInputModalOpen(true);
    // handleCardSelected();
  };

  const handleCancel = () => {
    setUserInputModalOpen(false);
    // handleCardUnselected();
  };

  return (
    <div className="card-container">
      {/* user input modal */}
      <UserInputModal
        card={card}
        lead = {lead}
        isLoading = {loading}
        isOpen={userInputModalOpen}
        onClose={handleCancel}
        onConfirm={handleModalConfirm} // You might want to handle the confirm action differently
      />

      {/* thankyou modal */}
      <ThankYouModal
        isOpen={isThankYouModalOpen}
        onClose={() => setThankYouModalOpen(false)}
      />

      <img
        src={card.imageUrl}
        alt={`${card.name} card`}
        className="card-image"
      />
      {/* <div className="card-header">
        <img
          src={card.bankLogoUrl}
          alt={`${card.bankName} logo`}
          className="bank-logo"
        />
      </div> */}
      <div className="card-info">
        <h4>{card.name}</h4>
        <p className="min-salary">
          Minimum Salary:{" "}
          <span className="salary-amount">{card.minimum_salary ?? "NA"}</span>
        </p>
        {card.description.includes("\n") ? (
          <div>
            {card.description
              .split("\n")
              .map(
                (line, index) =>
                  line.trim() !== "" && <li key={index}>{line}</li>
              )}
          </div>
        ) : (
          <p>{card.description}</p>
        )}
        <div className="features-list">
          {card.features != null &&
            card.features.map((featureId, index) => (
              <div key={index} className="feature-item">
                {getFeature(featureId)}
              </div>
            ))}
        </div>
        <button className="interest-button" onClick={handleImIntrested}>
          I'm Interested
        </button>
      </div>
    </div>
  );
};

export default CreditCardContainer;
