import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  androidUrl,
  firebaseStoragebaseUrl,
  firebaseStoragebaseUrlEnd,
  iosUrl,
} from "../global/AppConstants";
import appStoreImg from "../assets/img/app-store.png";
import playStoreImg from "../assets/img/play-store.png";
import appName from "../assets/img/app-name.png";
import "../style/OfferPage.css";

const OfferPage = () => {
  const [offerPath, setOfferPath] = useState("");

  const navigate = useNavigate();
  const params = useParams();

  const preLoadImage = new Image();

  const [imgLoaded, setImgLoaded] = useState(false);

  let path = params["path"];

  useEffect(() => {
    let temp = firebaseStoragebaseUrl;
    temp = temp.concat(path);
    temp = temp.concat(firebaseStoragebaseUrlEnd);
    setOfferPath(temp);
    console.log("offer");
    console.log(params);

    // loadImage(firebaseStoragebaseUrl + path + firebaseStoragebaseUrlEnd);
  }, []);

  const navigateToHome = () => {
    navigate("/");
  };

  const loadImage = (imageSrc) => {
    if (imageSrc === null || imageSrc === "") {
      return;
    } else {
      setImgLoaded(false);
      preLoadImage.onload = () => {
        // when it finishes loading, update the component state
        setImgLoaded(true);
      };

      preLoadImage.src = imageSrc; // by setting an src, you trigger browser download
    }
  };

  return (
    <div className="offer">
      {/* className="offer-preview-container" */}
      <div className=" container">
        
        <div className="row">
          <div className="col-md-4"></div>
          
          <div className="offer-preview-container col-md-4">
          <div style={{height : '25px'}}></div>

            <h1
              onClick={navigateToHome}
              data-aos="fade-up"
              data-aos-duration="400"
              className="app-name-img"
            >
              <img alt="" src={appName}></img>
            </h1>

            {
              // imgLoaded &&
              <div
                data-aos="fade"
                data-aos-duration="400"
                className="offer-img"
              >
                <img alt="" src={offerPath}></img>
              </div>
            }

            <div className="dowload-option-container">
              <p>

              To view all offers download free{" "}
                <span
                  onClick={navigateToHome}
                  style={{ color: "blue" }}
                  className=" link"
                >
                  DealsKart
                </span>{" "} App.
                Easily compare offers and promotions between different stores in{" "}
                <span
                  onClick={navigateToHome}
                  style={{ color: "blue" }}
                  className=" link"
                >
                  DealsKart
                </span>{" "}
                
                App
              </p>
              <div className="app-link-div">
                <div
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-duration="600"
                  data-aos-delay="250"
                  className="app-link"
                >
                  <Link target="_blank" to={iosUrl}>
                    <img alt="" src={appStoreImg}></img>
                  </Link>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-duration="800"
                  data-aos-delay="500"
                  className="app-link"
                >
                  <Link target="_blank" to={androidUrl}>
                    {" "}
                    <img alt="" src={playStoreImg}></img>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default OfferPage;
